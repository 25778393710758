import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg"; // Example icon, replace as needed

const Container = tw.div`w-full md:w-[60%] max-w-screen-lg mx-auto pt-6`;
const Heading = tw.h2`text-3xl font-bold text-start`;
const Subheading = tw.span`uppercase tracking-widest font-bold text-marketing-secslate`;
const Description = tw.p`mt-4 ml-0 mx-5 text-left text-lg text-marketing-secslate`;
const CardsContainer = tw.div`flex flex-col items-start`;
const Card = styled.div`
  ${tw`flex items-start px-1 py-2 mt-2 w-full`} 
  
  .content {
    ${tw`flex flex-col justify-center flex-grow`} /* Stack title and description */
  }

  .imageContainer {
    ${tw`rounded-full p-3 bg-marketing-priaqua mr-4`}
    i {
      ${tw`w-8 h-8 text-marketing-secslate`} 
      font-size: 2rem; /* Adjust icon size */
    }
  }

  .title {
    ${tw`font-bold text-xl text-marketing-secslate`} 
  }

  .description {
    ${tw`text-lg text-marketing-secslate`} 
  }
`;

const GetStartedLink = tw.a`flex items-center text-marketing-pricobolt text-lg font-semibold mt-8 hocus:text-marketing-secseafoam`;
const ArrowIcon = tw(ArrowRightIcon)`ml-2 w-4`;

const MainFeature1 = ({ heading, subheading, description, icons, bottomDescription }) => {
  return (
    <Container>
      {subheading && <Subheading>{subheading}</Subheading>}
      {heading && <Heading>{heading}</Heading>}
      {description && <Description>{description}</Description>}
      <CardsContainer>
        {icons.map((icon, index) => (
          <Card key={index}>
            
            <span className="imageContainer">
            <i className="a" >{icon.icon}</i>
            </span>
            <div className="header">
            <span className="title">{icon.heading}</span>
            
            <p className="description">{icon.description || ""}</p>
            </div>
          </Card>
        ))}
        {bottomDescription && (
          <Description>{bottomDescription}</Description>
        )}
        <GetStartedLink href="/scheduling">
        Schedule a Consultation Today
        <ArrowIcon />
      </GetStartedLink>
      </CardsContainer>
      
    </Container>
  );
};

export default MainFeature1;
