import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { Container as ContainerBase, ContentWithPaddingXl } from "components/misc/Layouts";
import { SectionDescription } from "components/misc/Typography";

const Container = tw(ContainerBase)`my-10 lg:my-10 bg-marketing-priaqua text-marketing-secslate -mx-8 px-8`;
const HeadingContainer = tw.div``;
const Heading = tw(SectionHeading)`sm:text-3xl md:text-4xl lg:text-5xl`;
const Subheading = tw(SubheadingBase)`text-gray-100 text-center`;
const Description = tw(SectionDescription)`text-marketing-secslate text-center mx-auto max-w-screen-md`;

// const StatsContainer = tw.div`mt-8 flex flex-col sm:flex-row items-center justify-center flex-wrap max-w-screen-md justify-between mx-auto`
// const Stat = tw.div`flex flex-col text-center p-4 tracking-wide`
// const StatKey = tw.div`text-xl font-medium`
// const StatValue = tw.div`text-4xl sm:text-3xl md:text-4xl lg:text-5xl font-black`

export default ({
  subheading = "",
  heading = "Let's Harness Your Potential (Together)",
  description = "We're dedicated to driving measurable growth and delivering a clear return on investment for your business.",
//   stats = [
//     {
//       key: "Clients",
//       value: "2500+",
//     },
//     {
//       key: "Revenue",
//       value: "$100M+",
//     },
//     {
//       key: "Employees",
//       value: "150+",
//     },
//   ]
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
        </HeadingContainer>
        {/* <StatsContainer>
          {stats.map((stat, index) => (
            <Stat key={index}>
              <StatValue>{stat.value}</StatValue>
              <StatKey>{stat.key}</StatKey>
            </Stat>
          ))}
        </StatsContainer> */}
      </ContentWithPaddingXl>
    </Container>
  );
};
