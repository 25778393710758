import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import SimpleContactUs from "components/forms/SolutionsContactUs";
import MainFeature1 from "components/features/SolutionsMainFeature";
import Header from "components/headers/MainHeader.js";
import SubHeader from "components/headers/SolutionsSubHeader";
import Footer from "components/footers/MainFooter";
import { PiWrench, PiPlug, PiDatabase, PiCloudArrowUpLight } from "react-icons/pi";
import { Helmet } from "react-helmet-async";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto pt-6 pb-20 md:py-6 md:pb-20 items-start`;

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-marketing-pricobolt`;
  const HighlightedText = tw.span`text-marketing-secslate`;

  const page1Icons = [
    {
      heading: "Unified Data Architecture",
      icon: <PiDatabase />,
      description: "Streamline and consolidate your data for clarity and consistency.",
    },
    {
      heading: "Scalable Infrastructure",
      icon: <PiCloudArrowUpLight />,
      description: "Create a foundation that evolves with your business.",
    },
    {
      heading: "Rapid Data Integration",
      icon: <PiPlug />,
      description: "Seamlessly connect systems for real-time insights.",
    },
    {
      heading: "Future-Proof Structuring",
      icon: <PiWrench />,
      description: "Ensure your data landscape is ready for tomorrow’s challenges.",
    },
  ];

  const bottomDescripton = "Empower your business with a data foundation designed to support innovation, agility, and long-term success."

  return (
    <AnimationRevealPage>
      <Helmet>
        <title>Data Infrastructure | Scalable, Secure, and Future-Ready Solutions | LariatCo</title>
        {/* <meta name="description" content="Optimize your business with LariatCo’s scalable data infrastructure solutions. Build a secure, unified architecture with seamless integration and future-proof technology for long-term success." />
        <link rel="canonical" href="https://www.lariatco.com/services/data-infrastructure" />
        <meta name="author" content="LariatCo" />

        <meta property="og:site_name" content="LariatCo" />
        <meta property="og:title" content="Data Infrastructure | Scalable, Secure, and Future-Ready Solutions | LariatCo" />
        <meta property="og:description" content="Unlock the full potential of your data with LariatCo’s infrastructure solutions. Scalable architecture, seamless integration, and secure, future-proof technology to support your growth." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.lariatco.com/services/data-infrastructure" />
        <meta property="og:image" content="https://www.lariatco.com/twogcard.png" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Data Infrastructure | Scalable, Secure, and Future-Ready Solutions | LariatCo" />
        <meta name="twitter:description" content="Transform your data infrastructure with LariatCo. Scalable solutions, seamless integration, and a secure, future-proof foundation for business growth." />
        <meta name="twitter:image" content="https://www.lariatco.com/twogcard.png" /> */}
      </Helmet>
        <Header />
        <Container>
        <SubHeader />
          <TwoColumn>
          
          <MainFeature1
          subheading={<Subheading>Data Technology</Subheading>}
          heading={
            <>
              <HighlightedText>Building a <span style={{ color: "#0000D3"}}> Scalable Foundation for Your Data</span></HighlightedText>
            </>
          }
          description="Revolutionize how your business handles data with a robust, scalable infrastructure designed to serve as your single source of truth. 
          Our solutions ensure that your data systems are easily accessible, highly adaptable, and built from the ground up to support your growth."
          icons={page1Icons}
          bottomDescription={bottomDescripton}
                  />
            
              <SimpleContactUs />
            
          </TwoColumn>
        </Container>
        <Footer />
      </AnimationRevealPage>
    );
  };
