import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import SimpleContactUs from "components/forms/SolutionsContactUs";
import MainFeature1 from "components/features/SolutionsMainFeature";
import Header from "components/headers/MainHeader.js";
import SubHeader from "components/headers/SolutionsSubHeader";
import Footer from "components/footers/MainFooter";
import { PiChartLineUp, PiArrowClockwise, PiGear, PiEye } from "react-icons/pi";
import { Helmet } from "react-helmet-async";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto pt-6 pb-20 md:py-6 md:pb-20 items-start`;

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-marketing-pricobolt`;
  const HighlightedText = tw.span`text-marketing-secslate`;

  const page1Icons = [
    {
      heading: "Real-Time Insights",
      icon: <PiArrowClockwise />, 
      description: "Make decisions backed by up-to-the-minute data.",
    },
    {
      heading: "Strategic Growth",
      icon: <PiChartLineUp />, 
      description: "Leverage data-driven strategies to unlock new opportunities.",
    },
    {
      heading: "Scalable Solutions",
      icon: <PiGear />, 
      description: "Tools that grow and adapt with your business needs.",
    },
    {
      heading: "Enhanced Visibility",
      icon: <PiEye />, 
      description: "See the full picture to identify trends and optimize performance.",
    },
  ];

  const bottomDescripton = "Turn your data into a driving force for smarter, faster, and more impactful decision-making."

  return (
    <AnimationRevealPage>
      <Helmet>
        <title>Business Intelligence | Turn Data into Growth with LariatCo</title>
        {/* <meta name="description" content="Unlock the power of data with LariatCo’s business intelligence solutions. Gain real-time insights, visualize key metrics, and drive data-driven growth with scalable, customized tools." />
        <link rel="canonical" href="https://www.lariatco.com/services/business-intelligence" />
        <meta name="author" content="LariatCo" />

        <meta property="og:site_name" content="LariatCo" />
        <meta property="og:title" content="Business Intelligence | Turn Data into Growth with LariatCo" />
        <meta property="og:description" content="Transform complex data into actionable insights with LariatCo’s business intelligence solutions. Leverage real-time dashboards, strategic analytics, and AI-driven insights to make smarter decisions." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.lariatco.com/services/business-intelligence" />
        <meta property="og:image" content="https://www.lariatco.com/twogcard.png" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Business Intelligence | Turn Data into Growth with LariatCo" />
        <meta name="twitter:description" content="LariatCo’s business intelligence services help you harness real-time data, visualize insights, and build data-driven strategies for success." />
        <meta name="twitter:image" content="https://www.lariatco.com/twogcard.png" /> */}
      </Helmet>
        <Header />
        <Container>
        <SubHeader />
          <TwoColumn>
          
          <MainFeature1
            subheading={<Subheading>Business Intelligence</Subheading>}
            heading={
              <>
                <HighlightedText>Transform Data into <span style={{ color: "#0000D3"}}>Actionable Intelligence</span></HighlightedText>
              </>
            }
            description="Harness the power of your data with advanced business intelligence solutions designed to fuel growth and efficiency. 
            We provide real-time insights, scalable tools, and strategies tailored to your business, empowering you to make informed decisions with clarity and confidence."
            icons={page1Icons}
            bottomDescription={bottomDescripton}
            />
            
              <SimpleContactUs />
            
          </TwoColumn>
        </Container>
        <Footer />
      </AnimationRevealPage>
    );
  };
