import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import SimpleContactUs from "components/forms/SolutionsContactUs";
import MainFeature1 from "components/features/SolutionsMainFeature";
import Header from "components/headers/MainHeader.js";
import SubHeader from "components/headers/SolutionsSubHeader";
import Footer from "components/footers/MainFooter";
import { PiCertificate, PiTrendUp, PiLockKeyOpen, PiShieldCheck } from "react-icons/pi";
import { Helmet } from "react-helmet-async";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto pt-6 pb-20 md:py-6 md:pb-20 items-start`;

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-marketing-pricobolt`;
  const HighlightedText = tw.span`text-marketing-secslate`;

  const page1Icons = [
    {
      heading: "Align with Regulations and Security Standards",
      icon: <PiCertificate />, 
      description: "Stay compliant and secure with expert guidance.",
    },
    {
      heading: "Scalable Security Architecture",
      icon: <PiTrendUp />,
      description: "Build a flexible framework that grows with your business.",
    },
    {
      heading: "Proactively Mitigate Vulnerabilities",
      icon: <PiShieldCheck />, 
      description: "Identify and address risks before they become problems.",
    },
    {
      heading: "Confidence Through Comprehensive Protection",
      icon: <PiLockKeyOpen />, 
      description: "Safeguard your operations with solutions you can rely on.",
    },
  ];

  const bottomDescripton = "Let us help you build a stronger, more adaptable foundation for your business's next chapter."

  return (
    <AnimationRevealPage>
      <Helmet>
        <title>Cybersecurity | Proactive Protection & Compliance | LariatCo</title>
        {/* <meta name="description" content="Secure your business with LariatCo’s proactive cybersecurity solutions. Protect against threats, ensure compliance, and implement scalable security measures tailored to your needs." />
        <link rel="canonical" href="https://www.lariatco.com/services/cyber-security" />
        <meta name="author" content="LariatCo" />

        <meta property="og:site_name" content="LariatCo" />
        <meta property="og:title" content="Cybersecurity | Proactive Protection & Compliance | LariatCo" />
        <meta property="og:description" content="Safeguard your business with LariatCo’s cybersecurity solutions. Mitigate risks, ensure compliance, and build a resilient, scalable security framework for long-term protection." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.lariatco.com/services/cyber-security" />
        <meta property="og:image" content="https://www.lariatco.com/twogcard.png" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Cybersecurity | Proactive Protection & Compliance | LariatCo" />
        <meta name="twitter:description" content="Defend your organization with LariatCo’s cybersecurity solutions. Ensure compliance, prevent cyber threats, and secure your business with scalable security measures." />
        <meta name="twitter:image" content="https://www.lariatco.com/twogcard.png" /> */}
      </Helmet>
        <Header />
        <Container>
        <SubHeader />
          <TwoColumn>
          
          <MainFeature1
          subheading={<Subheading>Cybersecurity</Subheading>}
          heading={
            <>
              <HighlightedText>Proactive Protection for<span style={{ color: "#0000D3"}}> Peace of Mind</span></HighlightedText>
            </>
          }
          description="Shield your business from evolving threats with cybersecurity solutions designed to protect what matters most. 
          From tailored security architectures to vulnerability management and regulatory compliance, we deliver end-to-end protection you can trust."
          icons={page1Icons}
          bottomDescription={bottomDescripton}
                  />
            
              <SimpleContactUs />
            
          </TwoColumn>
        </Container>
        <Footer />
      </AnimationRevealPage>
    );
  };
