import React from "react";
import { createRoot } from 'react-dom/client';
import App from "./App";
import Modal from "react-modal";
import { HelmetProvider } from "react-helmet-async";

// PostHog
import { PostHogProvider } from "posthog-js/react";

const options = {
  api_host: process.env.POSTHOG_HOST, 
};
// PostHog

Modal.setAppElement("#root");

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <React.StrictMode>
      <HelmetProvider>
      <PostHogProvider 
        apiKey={process.env.POSTHOG}
        options={options}
      >
        <App />
      </PostHogProvider>
      </HelmetProvider>
    </React.StrictMode>
  );