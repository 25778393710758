// ** this is the original ThreeColSimple **//
import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

import { PiCheck } from "react-icons/pi";
import { PiBookOpenTextThin } from "react-icons/pi";
import { PiTarget } from "react-icons/pi";
import { PiLightbulbFilamentLight } from "react-icons/pi";
import { PiShieldCheckered } from "react-icons/pi";

const Heading = tw(SectionHeading)`text-marketing-secslate`;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`text-center mx-auto text-marketing-secslate`;
const ThreeColumnContainer = styled.div`
  ${tw`mt-10 flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap lg:justify-center max-w-screen-lg mx-auto`}
`;
const Column = styled.div`
  ${tw`lg:w-1/3 max-w-xs`}
`;

const Card = styled.a`
  ${tw`flex flex-col items-center text-center h-full mx-4 px-4 py-8 rounded transition-transform duration-300 transform hover:scale-105 `}
  

  .title {
    ${tw`mt-4 font-bold text-xl text-marketing-secslate leading-none`}
  }

  .description {
    ${tw`mt-4 text-sm font-medium text-marketing-secslate`}
  }

  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-40`}
`;


export default ({
  cards = [
    {
      imageSrc: <PiCheck />,
      icon: <PiCheck />,
      title: "Keep it Simple",
      description: "We prioritize straightforward, effective solutions that streamline growth and efficiency.", 
    },
    {
      imageSrc: <PiBookOpenTextThin />,
      icon: <PiBookOpenTextThin />,
      title: "Be Authentic",
      description: "Genuine care and attention guide everything we do, ensuring that we deliver real, meaningful impact.",
    },
    {
      imageSrc: <PiTarget />,
      icon: <PiTarget />,
      title: "Deliver",
      description: "Our focus is on measurable results and actionable ROI, making every investment worthwhile.",
    },
    {
      imageSrc: <PiLightbulbFilamentLight />,
      icon: <PiLightbulbFilamentLight />,
      title: "Innovate",
      description: "We stay ahead of the ever-changing business, data, and technology landscape to keep you at the forefront.",
    },
    {
      imageSrc: <PiShieldCheckered />,
      icon: <PiShieldCheckered/>,
      title: "Protect",
      description: "Your trust is our highest priority. We’re dedicated to safeguarding the critical elements that drive your business's success.",
    }
  ],
  heading = "What Drives Us",
  subheading = "",
  description = "We strive to be your trusted, long-term partner, guided by the principles that shape every decision and action we take.",
  imageContainerCss = null,
  imageCss = null
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        {subheading && <Subheading>{subheading}</Subheading>}
        {heading && <Heading>{heading}</Heading>}
        {description && <Description>{description}</Description>}
        <ThreeColumnContainer>
          {cards.map((card, i) => (
            <Column key={i}>
              <Card >
              <span className="icon" style={{ fontSize: "2rem", color: "#243E63"  }}>
              {card.icon}
            </span>
                <span className="title">{card.title}</span>
                <p className="description">{card.description}</p>
                
              </Card>
            </Column>
          ))}
        </ThreeColumnContainer>
      </ContentWithPaddingXl>
      <DecoratorBlob />
    </Container>
  );
};
