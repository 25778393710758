// ** I have made my edits of customization **/ 
import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import TeamIllustrationSrc from "../../images/stock/process.jpg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`px-6 flex flex-col md:flex-col justify-between max-w-screen-2xl mx-auto py-12 md:py-12 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`flex-shrink-0 relative md:w-full`;
const TextColumn = styled(Column)(props => [
  tw`mx-16 md:mt-0 md:w-full`,
  props.textOnLeft ? tw`md:order-last` : tw`md:order-last`
]);

const Image = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`
]);

const DecoratorBlob = styled(SvgDotPattern)(() => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`
]);

const TextContent = tw.div`lg:py-8 text-marketing-secslate text-center md:text-center`;

const Subheading = tw(SubheadingBase)`text-center text-xl mt-16 md:text-center`;
const Heading = tw(
  SectionHeading
)`mt-4 text-marketing-secslate text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-center leading-tight`;

const Steps = tw.ul`mt-12 grid grid-cols-1 sm:grid-cols-2 gap-y-8 gap-x-8`; // Two steps per row
const Step = tw.li`flex flex-col items-center text-center`; // Aligns items properly
const StepNumber = tw.div`font-semibold text-4xl leading-none text-gray-400`;
const StepText = tw.div`mt-3`; // Removed unnecessary margin for grid layout
const StepHeading = tw.h6`leading-none text-xl font-semibold`;
const StepDescription = tw.p`mt-3 max-w-xs leading-loose text-sm text-marketing-secslate font-medium`;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-12 text-sm bg-marketing-secslate inline-block mx-auto hocus:bg-marketing-priaqua hocus:text-marketing-pricobolt`,
  props.buttonRounded && tw`rounded-full`
]);

export default ({
  subheading = "",
  heading = (
    <>
      Partnering for <span tw="text-marketing-pricobolt">PROGRESS.</span>
    </>
  ),
  primaryButtonText = "Get Started",
  primaryButtonUrl = "/scheduling",
  buttonRounded = true,
  imageSrc = TeamIllustrationSrc,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  imageDecoratorBlob = false,
  textOnLeft = true,
  steps = null,
  decoratorBlobCss = null,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  const defaultSteps = [
    {
      heading: "Assess",
      description: "We begin by understanding your current state, future aspirations, and the outcomes you're striving for."
    },
    {
      heading: "Plan",
      description: "We design and build a solution that aligns with your goals and integrates seamlessly with your operations."
    },
    {
      heading: "Activate",
      description: "We put the plan into action, ensuring stakeholders are empowered and confident through the transition."
    },
    {
      heading: "Manage and Maintain",
      description: "With proactive support and ongoing management, we help you sustain success and adapt to new processes."
    }
  ];

  if (!steps) steps = defaultSteps;

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image src={imageSrc} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} />
          {imageDecoratorBlob && <DecoratorBlob css={decoratorBlobCss} />}
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Subheading>{subheading}</Subheading>
            <Heading>{heading}</Heading>
            <Steps>
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepNumber>{(index+1).toString().padStart(2,'0')}</StepNumber>
                  <StepText>
                    <StepHeading>{step.heading}</StepHeading>
                    <StepDescription>{step.description}</StepDescription>
                  </StepText>
                </Step>
              ))}
            </Steps>
            <PrimaryButton buttonRounded={buttonRounded} as="a" href={primaryButtonUrl}>
              {primaryButtonText}
            </PrimaryButton>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
