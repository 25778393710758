import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { SectionHeading } from "components/misc/Headings.js";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

const Container = tw.div`relative`;
const CenteredContainer = tw.div`flex flex-col items-center justify-center max-w-screen-lg mx-auto mb-20 py-10 md:py-10`;
const ThreeColumnContainer = tw.div`flex flex-col md:flex-row justify-between w-full gap-8`;
const Column = tw.div`w-full md:w-1/3 flex justify-center`;
const Heading = tw(SectionHeading)`w-full text-center text-marketing-secslate`;

const Card = styled.div`
  ${tw`flex flex-col items-center text-center h-full mx-2 px-2 py-4`}
  .title {
    ${tw`mt-1 mb-4 tracking-wide font-bold text-2xl leading-none text-marketing-secslate`}
  }
  .addressLine1 {
    ${tw`mt-3 font-medium text-marketing-pricobolt leading-loose`}
  }
  .addressLine2 {
    ${tw`font-medium text-marketing-pricobolt leading-tight mt-1`} 
  }
  .phone {
    ${tw`mt-3 font-medium text-marketing-pricobolt`}  
  }
    .email {
      ${tw`mt-3 font-medium text-marketing-pricobolt leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({
  heading = "Contact Details",
  streetAddress = "P.O. Box 55321",
  cityStateZip = "Indianapolis, IN 46256",
  email1 = "contact@lariatco.com",
  email2 = "marketing@lariatco.com",
  phone = "(317) 785-6040"
}) => {
  return (
    <Container>
      <CenteredContainer>
        <Heading>{heading}</Heading>
      <ThreeColumnContainer>
        <Column>
          <Card>
          <p className="title">Location</p>
            <a
              href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(`${streetAddress}, ${cityStateZip}`)}`}
              target="_blank"
              rel="noopener noreferrer"
            >
            <p className="addressLine1">{streetAddress}</p>
            <p className="addressLine2">{cityStateZip}</p>
            </a>
          </Card>
        </Column>
        <Column>
          <Card>
            <p className="title">Phone</p>
            <p className="phone"><a href={`tel:${phone}`}>{phone}</a></p>
          </Card>
        </Column>
        <Column>
          <Card>
            <p className="title">Email</p>
            <p className="email"><a href={`mailto:${email1}`}>{email1}</a></p>
            <p className="email"><a href={`mailto:${email2}`}>{email2}</a></p>
          </Card>
        </Column>
      </ThreeColumnContainer>
    </CenteredContainer>
      <DecoratorBlob />
    </Container>
  );
};
