import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { GiWeightLiftingUp } from "react-icons/gi";
import { GiMountainCave } from "react-icons/gi";
import { GiCheckMark } from "react-icons/gi";
import { GiInfinity } from "react-icons/gi";


const Container = tw.div`relative mb-8`;
const TwoColumn = tw.div` flex flex-col justify-between md:w-full max-w-screen-2xl mx-auto py-12 pb-4 md:py-12 md:pb-4 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none `;
const TextColumn = styled(Column)(props => [
  tw`w-full mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const TextContent = tw.div`lg:py-8 text-marketing-secslate text-center `;

const Subheading = tw(SubheadingBase)` text-xl md:mt-4 text-center`;
const Heading = tw(
  SectionHeading
)`mt-4 text-marketing-secslate text-center text-3xl sm:text-4xl lg:text-5xl leading-tight`;
const Description = tw.p`mt-8 text-base md:text-center text-sm lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Features = tw.div`mx-0 flex flex-col lg:flex-row max-w-xs lg:max-w-none md:max-w-full md:justify-center md:gap-0 gap-y-8 gap-x-8 sm:flex-row sm:flex-wrap`;
const Feature = tw.div`flex flex-col items-center md:items-center w-full lg:w-1/4 md:mx-auto text-center md:text-left sm:w-1/2`;

const FeatureHeadingContainer = tw.div`flex items-center justify-center md:justify-start text-center md:text-left`;
const FeatureIconContainer = styled.div`
  ${tw`mx-auto inline-block border border-marketing-pricobolt text-white text-center md:items-start md:text-left rounded p-2 flex-shrink-0`}
  ${props => [
    props.iconRoundedFull && tw`rounded-full `,
    props.iconFilled && tw`border-0 text-white`
  ]}
  svg {
    ${tw`w-5 h-5`}
  }
`;
const FeatureHeading = tw.div`ml-3 font-bold text-marketing-secslate text-xl md:text-left`;

const FeatureDescription = tw.div`mt-3 md:px-4 md:pb-4 text-center md:text-center text-marketing-secslate `;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-12 md:mt-6 text-sm bg-marketing-secslate inline-block mx-auto md:items-center hocus:bg-marketing-priaqua hocus:text-marketing-pricobolt`,
  tw`text-center md:mx-auto`,
  props.buttonRounded && tw`rounded-full`
]);

export default ({
  subheading = "",
  heading = (
    <>
      A <span tw="text-marketing-pricobolt">PARTNERSHIP</span> approach.
    </>
  ),
  description = "",
  primaryButtonText = "Discover More",
  primaryButtonUrl = "/aboutus",
  buttonRounded = true,
  textOnLeft = true,
  features = null,
  iconRoundedFull = true,
  iconFilled = true,
  iconContainerCss = null
}) => {
 
  const defaultFeatures = [
    {
      Icon: GiMountainCave,
      title: "Big Picture Thinking",
      description: "We take a holistic view of your business, aligning all functions to create comprehensive, future-focused strategies.",
      iconContainerCss: tw`bg-marketing-priaqua text-marketing-secslate`, 

    },
    {
      Icon: GiCheckMark,
      title: "Custom Solutions",
      description: "Every business is unique. We design solutions tailored to your specific goals and challenges, ensuring maximum impact.",
      iconContainerCss: tw`bg-marketing-priaqua text-marketing-secslate`, 

    }, 
    {
      Icon: GiWeightLiftingUp,
      title: "Empowering Transitions",
      description: "Our goal is not just implementation but empowering your team with knowledge and tools for confident control.",
      iconContainerCss: tw`bg-marketing-priaqua text-marketing-secslate`,

    },
    {
      Icon: GiInfinity,
      title: "Long-Term Commitment",
      description: "We provide ongoing support to ensure your solutions continue to deliver value as your business evolves.",
      iconContainerCss: tw`bg-marketing-priaqua text-marketing-secslate`,

    }, 
    
  ];

  if (!features) features = defaultFeatures;

  return (
    <Container>
      <TwoColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Subheading>{subheading}</Subheading>
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            <Features>
              {features.map((feature, index) => (
                <Feature key={index} href={features.url}>
                  <FeatureHeadingContainer>
                    <FeatureIconContainer
                      iconFilled={iconFilled}
                      iconRoundedFull={iconRoundedFull}
                      css={feature.iconContainerCss || iconContainerCss}
                    >
                      {<feature.Icon />}
                    </FeatureIconContainer>
                    <FeatureHeading>{feature.title}</FeatureHeading>
                  </FeatureHeadingContainer>
                  <FeatureDescription>{feature.description}</FeatureDescription>
                  </Feature>
              ))}
            </Features>

            <PrimaryButton buttonRounded={buttonRounded} as="a" href={primaryButtonUrl}>
              {primaryButtonText}
            </PrimaryButton>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
