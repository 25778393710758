import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import SimpleContactUs from "components/forms/SolutionsContactUs";
import MainFeature1 from "components/features/SolutionsMainFeature";
import Header from "components/headers/MainHeader.js";
import SubHeader from "components/headers/SolutionsSubHeader";
import Footer from "components/footers/MainFooter";
import { FaClipboardList, FaLightbulb, FaRegChartBar, FaUserTie } from "react-icons/fa";
import { Helmet } from "react-helmet-async";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto pt-6 pb-20 md:py-6 md:pb-20 items-start`;

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-marketing-pricobolt`;
  const HighlightedText = tw.span`text-marketing-secslate`;

  const page1Icons = [
    {
      heading: "Access Experienced Fractional CIOs",
      icon: <FaUserTie />,
      description: "Strategic leadership without the long-term commitment.",
    },
    {
      heading: "Flexible, On-Demand Support",
      icon: <FaLightbulb />,
      description: "Tailored solutions for your unique challenges.",
    },
    {
      heading: "Close Leadership Gaps",
      icon: <FaClipboardList />,
      description: "Expert insights to strengthen your team.",
    },
    {
      heading: "Optimize and Scale",
      icon: <FaRegChartBar />,
      description: "Build systems designed to grow with your business.",
    },
  ];

  const bottomDescripton = "Step confidently into the future with flexible leadership that delivers results."

  return (
    <AnimationRevealPage>
      <Helmet>
        <title>Fractional Leadership | Expert CIO Guidance for Scalable Growth | LariatCo</title>
        {/* <meta name="description" content="Leverage LariatCo’s fractional CIO leadership to fill expertise gaps, drive data strategy, scale infrastructure, and optimize technology operations with flexible, high-impact leadership." />
        <link rel="canonical" href="https://www.lariatco.com/services/fractional-leadership" />
        <meta name="author" content="LariatCo" />

        <meta property="og:site_name" content="LariatCo" />
        <meta property="og:title" content="Fractional Leadership | Expert CIO Guidance for Scalable Growth | LariatCo" />
        <meta property="og:description" content="Access experienced fractional CIOs to lead your data strategy, optimize technology infrastructure, and scale your business with flexible executive leadership from LariatCo." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.lariatco.com/services/fractional-leadership" />
        <meta property="og:image" content="https://www.lariatco.com/twogcard.png" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Fractional Leadership | Expert CIO Guidance for Scalable Growth | LariatCo" />
        <meta name="twitter:description" content="Fill leadership gaps with LariatCo’s fractional CIO services. Drive data strategy, optimize infrastructure, and scale technology initiatives with expert guidance." />
        <meta name="twitter:image" content="https://www.lariatco.com/twogcard.png" /> */}
      </Helmet>
        <Header />
        <Container>
        <SubHeader />
          <TwoColumn>
          
          <MainFeature1
          subheading={<Subheading>Fractional Leadership</Subheading>}
          heading={
            <>
              <HighlightedText>Expertise <span style={{ color: "#0000D3"}}> When You Need It Most</span></HighlightedText>
            </>
          }
          description="Harness the power of on-demand data and technology leadership to drive your business forward. Our seasoned fractional leaders bring CIO-level expertise to bridge gaps, optimize infrastructure, and scale your systems with precision. Whether you’re navigating complex initiatives or enhancing data governance, we provide the guidance you need, exactly when you need it."
          icons={page1Icons}
          bottomDescription={bottomDescripton}
          />
            
              <SimpleContactUs />
            
          </TwoColumn>
        </Container>
        <Footer />
      </AnimationRevealPage>
    );
  };
