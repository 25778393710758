import React from "react";
import tw from "twin.macro";
import styled, { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import {  Subheading as SubheadingBase } from "components/misc/Headings.js";



const PrimaryBackgroundContainer = tw(Container)`-mx-8 bg-marketing-priaqua px-4 sm:px-6 lg:px-8 py-6`;
const HeadingContainer = tw.div`text-left w-full`
const Subheading = tw(SubheadingBase)`text-left text-2xl text-marketing-pricobolt mb-4`;
const Paragraph = tw.p` mt-2 mb-8 text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-marketing-secslate`;
const Span = styled.span`
  ${tw`font-bold text-left text-2xl text-marketing-pricobolt`} 
`;
const Desc = styled.span`
  ${tw`font-medium text-left md:text-base lg:text-lg text-marketing-secslate`} 
`;

export default ({
  subheading = "",

  subheading2 = "",
  subheading3 = "",
  
}) => {
  
    return (
        <PrimaryBackgroundContainer>
          <ContentWithPaddingXl>
            <HeadingContainer>
              <Subheading>{subheading}</Subheading>            
              <Paragraph>
              With decades of experience at the intersection of business ownership, strategy, and leadership, we offer unparalleled 
              insights into the challenges and opportunities organizations face today. Drawing from a legacy of hands-on expertise and 
              innovative thinking, we collaborate with leadership teams to tackle complex problems, fuel sustainable growth, and design 
              strategies that lay the groundwork for a resilient and thriving future.
              Your leadership and vision have positioned your organization for success — our role is to help you take it to the next level.
          </Paragraph>
          <Subheading>{subheading2}</Subheading>
          <Paragraph>
          We begin by conducting a thorough assessment to identify how technology, operational efficiency, and process optimization can elevate 
          your business. Whether you need to ensure compliance, enhance productivity, strengthen cybersecurity, or increase visibility, we develop a 
          tailored roadmap aligned with your unique goals.
          <br />
          But this isn't just about technology. It's about equipping every part of your business to manage challenges, sustain growth, and navigate 
          future risks. Our focus is on making solutions practical and understandable, empowering you to integrate systems, unlock valuable insights, 
          and focus on what you do best.
          </Paragraph>
          <Span>{subheading3}</Span><Desc>ensuring your business runs securely and efficiently so you can grow with confidence and purpose.</Desc>
            </HeadingContainer>
            </ContentWithPaddingXl>
        </PrimaryBackgroundContainer>
  );
};
