// ** I have updated this page for my custom work
import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";
import { TbChartHistogram } from "react-icons/tb";
import { AiOutlineCloudServer } from "react-icons/ai";
import { FaRegHandshake } from "react-icons/fa";
import { IoGitMergeOutline } from "react-icons/io5";
import { BsDatabaseCheck, BsShieldLock } from "react-icons/bs";

const Heading = tw(SectionHeading)``;
const Subheading = tw(SubheadingBase)`text-center text-xl mb-3`;
const Description = tw(SectionDescription)`text-center mx-auto`;
const ThreeColumnContainer = styled.div`
  ${tw`mt-10 flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap lg:justify-center max-w-screen-lg mx-auto`}
`;
const Column = styled.div`
  ${tw`lg:w-1/2 max-w-xs`}
`;

const Card = styled.a`
  ${tw`flex flex-col items-center text-center h-full mx-4 px-4 py-8 rounded transition-transform duration-300 hover:cursor-pointer transform hover:scale-105 `}
  .imageContainer {
    ${tw`text-center rounded-full p-4 bg-gray-100`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .title {
    ${tw`mt-4 font-bold text-marketing-pricobolt text-xl leading-none`}
  }

  .description {
  ${tw`mt-4 text-sm font-medium text-marketing-secslate`} 
}

  .link {
    ${tw`mt-auto inline-flex items-center pt-5 text-sm font-bold text-marketing-pricobolt leading-none hocus:text-marketing-secseafoam transition duration-300`}
    .icon {
      ${tw`ml-2 w-6 text-marketing-secslate`}
    }
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-40`}
`;

export default ({
    cards = [
      {
        imageSrc: <IoGitMergeOutline />,
        icon: <IoGitMergeOutline />,
        title: "Integration",
        description: "Streamlining  platforms, integrating systems, and creating a scalable foundation for future growth.",
        url: "/system-integration"
      },
      {
        imageSrc: <TbChartHistogram />,
        icon: <TbChartHistogram />,
        title: "Business Intelligence",
        description: "Transforming raw data into actionable insights, empowering better decision-making with clear, reliable visibility.",
        url: "/business-intelligence"
      },
      {
        imageSrc: <AiOutlineCloudServer />,
        icon: <AiOutlineCloudServer />,
        title: "Technology Architecture",
        description: "Custom-built, scalable solutions designed to work seamlessly across diverse platforms, enabling adaptability and growth.",
        url: "/technology-architecture"
      },
    {
      imageSrc: <BsDatabaseCheck />,
      icon: <BsDatabaseCheck />,
      title: "Data Infrastructure",
      description: "Establishing a secure, scalable single source of truth for your data, built to evolve with your business.",
      url: "/data-infrastructure"
    },
    {
      imageSrc: <BsShieldLock />,
      icon: <BsShieldLock />,
      title: "Cyber Security",
      description: "Proactively defending your business with strategic planning and robust security measures to stay ahead of threats.",
      url: "/cyber-security"
    },
    {
      imageSrc: <FaRegHandshake />,
      icon: <FaRegHandshake />,
      title: "Fractional Leadership",
      description: "Flexible, part-time executive support to maximize resources, enhance strategy, and drive results.",
      url: "/fractional-leadership"
    },
  ],
  linkText = "Learn More",
  heading = "",
  subheading = "",
  description = "",
  imageContainerCss = null,
  imageCss = 'size-4'
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        {subheading && <Subheading>{subheading}</Subheading>}
        {heading && <Heading>{heading}</Heading>}
        {description && <Description>{description}</Description>}
        <ThreeColumnContainer>
          {cards.map((card, i) => (
            <Column key={i}>
              <Card href={card.url}> 
                <span className="icon" style={{ fontSize: "2rem", color: "#243E63" }}>
                  {card.icon}
                  </span>
                  
                <span className="title">{card.title}</span>
                <p className="description">{card.description}</p>
                {linkText && (
                  <span className="link">
                    <span>{linkText}</span>
                    <ArrowRightIcon className="icon" />
                  </span>
                )}
              </Card>
            </Column>
          ))}
        </ThreeColumnContainer>
      </ContentWithPaddingXl>
      <DecoratorBlob />
    </Container>
  );
};
