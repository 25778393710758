import MainHeader from 'components/headers/MainHeader'
import React from 'react'
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Helmet } from "react-helmet-async";

const Scheduling = () => {
  return (
    <>
    <AnimationRevealPage>
    <Helmet>
      <title>Schedule a Consultation | LariatCo</title>
      {/* <meta name="description" content="Book a consultation with LariatCo to explore solutions for your business. Schedule a meeting with our experts and take the next step toward success." />
      <link rel="canonical" href="https://www.lariatco.com/scheduling" />
      <meta name="author" content="LariatCo" />

      <meta property="og:site_name" content="LariatCo" />
      <meta property="og:title" content="Schedule a Consultation | LariatCo" />
      <meta property="og:description" content="Easily schedule a consultation with LariatCo to discuss your business needs and explore tailored solutions." />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.lariatco.com/scheduling" />
      <meta property="og:image" content="https://www.lariatco.com/twogcard.png" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Schedule a Consultation | LariatCo" />
      <meta name="twitter:description" content="Book a consultation with LariatCo to explore expert business solutions and take your company to the next level." />
      <meta name="twitter:image" content="https://www.lariatco.com/twogcard.png" /> */}
    </Helmet>

    <MainHeader />
    <div style={{ width: '100vw', height: '100vh', margin: 0, paddingTop: 24 }}>
      <iframe
        src="https://outlook.office365.com/owa/calendar/LariatCo2@lariatco.com/bookings/"
        width="100%"
        height="100%"
        scrolling="yes"
        style={{ border: 0, }}
        title="Booking Information"
      ></iframe>
    </div>
    </AnimationRevealPage>
    </>
  )
}

export default Scheduling