import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { Container as ContainerBase, ContentWithPaddingXl } from "components/misc/Layouts";
import { SectionDescription } from "components/misc/Typography";

const Container = tw(ContainerBase)`my-8 lg:my-10 bg-marketing-priaqua text-marketing-secslate -mx-4 sm:-mx-6 lg:mx-0 px-4 sm:px-6 lg:px-8 py-6`;
const HeadingContainer = tw.div`text-center w-full px-4 lg:px-8`;
const Heading = tw(SectionHeading)`sm:text-3xl md:text-4xl lg:text-5xl mb-4`;
const Subheading = tw(SubheadingBase)`text-gray-100 text-left sm:text-center px-2 lg:px-8`;
const Description = tw(SectionDescription)`text-marketing-secslate text-center sm:text-center mx-auto px-2 sm:px-2 lg:px-8 max-w-screen-md`;

export default ({
  subheading = "",
  heading = "Our why",
  description = <>We believe small and medium-sized businesses (SMBs) are the backbone of the global economy. Despite operating 
  with fewer resources and facing greater challenges, they exemplify hard work, perseverance, and innovation. Too often, their 
  immense contributions go unnoticed. We're dedicated to changing that by empowering SMBs to overcome obstacles, seize opportunities, 
  and achieve lasting success.</>
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
        </HeadingContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};
