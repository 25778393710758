import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import SimpleContactUs from "components/forms/SolutionsContactUs";
import MainFeature1 from "components/features/SolutionsMainFeature";
import Header from "components/headers/MainHeader.js";
import SubHeader from "components/headers/SolutionsSubHeader";
import Footer from "components/footers/MainFooter";
import { PiArrowCircleRight } from "react-icons/pi";
import { IoIosGitMerge } from "react-icons/io";
import { MdOutlineHistory } from "react-icons/md";
import { FiArrowRight } from "react-icons/fi";
import { Helmet } from "react-helmet-async";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto pt-6 pb-20 md:py-6 md:pb-20 items-start`;

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-marketing-pricobolt`;
  const HighlightedText = tw.span`text-marketing-secslate`;

  const page1Icons = [
    {
      heading: "Connect Everything",
      icon: <IoIosGitMerge />, 
      description: "Seamless integration for SaaS tools, M&A projects, and beyond.",
    },
    {
      heading: "Modernize with Confidence",
      icon: <MdOutlineHistory />, 
      description: "Upgrade outdated systems without disrupting operations.",
    },
    {
      heading: "Future-Ready Tech Stacks",
      icon: <PiArrowCircleRight />, 
      description: "Build a foundation designed for growth and innovation.",
    },
    {
      heading: "Streamline and Automate",
      icon: <FiArrowRight />, 
      description: "Enable smoother workflows and improved data flow.",
    },
  ];

  const bottomDescripton = "Simplify complexity, enhance efficiency, and empower your business to thrive in an ever-evolving landscape."

  return (
    <AnimationRevealPage>
      <Helmet>
      <title>System Integration | Connect, Automate, and Modernize with LariatCo</title>
        {/* <meta name="description" content="Seamlessly integrate your systems with LariatCo. Modernize legacy infrastructure, automate workflows, and build a future-proof tech stack for efficient business operations." />
        <link rel="canonical" href="https://www.lariatco.com/services/system-integration" />
        <meta name="author" content="LariatCo" />

        <meta property="og:site_name" content="LariatCo" />
        <meta property="og:title" content="System Integration | Connect, Automate, and Modernize with LariatCo" />
        <meta property="og:description" content="Simplify complexity with LariatCo’s system integration solutions. Upgrade legacy systems, enable automation, and create a flexible, connected tech ecosystem." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.lariatco.com/services/system-integration" />
        <meta property="og:image" content="https://www.lariatco.com/twogcard.png" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="System Integration | Connect, Automate, and Modernize with LariatCo" />
        <meta name="twitter:description" content="Transform your business with LariatCo’s expert system integration. Eliminate silos, streamline processes, and modernize your technology for scalability." />
        <meta name="twitter:image" content="https://www.lariatco.com/twogcard.png" /> */}
      </Helmet>
        <Header />
        <Container>
        <SubHeader />
          <TwoColumn>
          
          <MainFeature1
    subheading={<Subheading>System Integration</Subheading>}
    heading={
      <>
        <HighlightedText>Seamless Connectivity, <span style={{ color: "#0000D3"}}>Simplified Results</span></HighlightedText>
      </>
    }
    description="Transform disconnected systems into a cohesive, efficient operation with expert integration solutions. Whether bridging legacy platforms, streamlining M&A transitions, or preparing for future innovations, we ensure your technology works together effortlessly to drive success and adaptability."
    icons={page1Icons}
    bottomDescription={bottomDescripton}
            />
            
              <SimpleContactUs />
            
          </TwoColumn>
        </Container>
        <Footer />
      </AnimationRevealPage>
    );
  };
