import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

const SubHeader = tw.header`
  flex flex-col justify-center items-center
  max-w-screen-xl mx-auto pt-20 pb-4
  rounded-lg relative
`;

export const NavLinks = tw.div`inline-block text-center`;

const isActive = (href) => window.location.pathname === href;

export const NavLink = styled.a`
  ${tw`text-lg my-2 lg:text-sm lg:mx-6 lg:my-0 font-semibold tracking-wide transition duration-300 pb-1 border-b-2 border-transparent hover:border-marketing-pricobolt hocus:text-marketing-pricobolt`}
  ${({ href }) => isActive(href) && tw`border-marketing-pricobolt text-marketing-pricobolt`}
`;

export const MobileNavLinksContainer = tw.nav`
  flex flex-1 flex-col items-center justify-center
`;

export const MobileNavLinks = styled.div`
  ${tw`flex flex-col items-center p-4 text-center text-gray-900 bg-white rounded-lg w-full lg:hidden`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`;

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-center items-center
`;

export default ({ links, className, collapseBreakpointClass = "lg" }) => {
  const defaultLinks = [
    <NavLinks key={1}>
      <NavLink href="/business-assessment">Assessment</NavLink>
      <NavLink href="/system-integration">Integration</NavLink>
      <NavLink href="/business-intelligence">Business Intelligence</NavLink>
      <NavLink href="/technology-architecture">Technology Infrastructure</NavLink>
      <NavLink href="/data-infrastructure">Data Infrastructure</NavLink>
      <NavLink href="/fractional-leadership">Fractional Leadership</NavLink>
      <NavLink href="/cyber-security">Cyber Security</NavLink>
    </NavLinks>
  ];

  links = links || defaultLinks;

  return (
    <SubHeader className={className || "header-light"}>
      <DesktopNavLinks>
        {links}
      </DesktopNavLinks>

      <MobileNavLinksContainer>
        <MobileNavLinks>
          {links}
        </MobileNavLinks>
      </MobileNavLinksContainer>
    </SubHeader>
  );
};
