import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import SimpleContactUs from "components/forms/SolutionsContactUs";
import MainFeature1 from "components/features/SolutionsMainFeature";
import Header from "components/headers/MainHeader.js";
import SubHeader from "components/headers/SolutionsSubHeader";
import Footer from "components/footers/MainFooter";
import { PiListChecks, PiShieldWarningLight, PiRocketLaunch, PiMapPinArea } from "react-icons/pi";
import { Helmet } from "react-helmet-async";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto pt-6 pb-20 md:py-6 items-start`;

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-marketing-pricobolt`;
  const HighlightedText = tw.span`text-marketing-secslate`;

  const page1Icons = [
    {
      heading: "Comprehensive Technology Audits",
      icon: <PiListChecks />, 
      description: "Uncover inefficiencies and risks.",
    },
    {
      heading: "Pinpoint Challenges",
      icon: <PiShieldWarningLight />,
      description: "Address gaps that hinder progress.",
    },
    {
      heading: "Scalable Roadmaps",
      icon: <PiRocketLaunch />, 
      description: "Plan for future-ready modernization.",
    },
    {
      heading: "Actionable Insights",
      icon: <PiMapPinArea />, 
      description: "Gain clear direction and realistic budgets.",
    },
  ];

  const bottomDescripton = "Let us help you build a stronger, more adaptable foundation for your business's next chapter."

  return (
    <AnimationRevealPage>
      <Helmet>
        <title>Business Assessments | Identify, Optimize, and Grow with LariatCo</title>
        {/* <meta name="description" content="Gain strategic insights with LariatCo’s business assessments. Identify inefficiencies, ensure compliance, and build a modernization roadmap for sustainable growth." />
        <link rel="canonical" href="https://www.lariatco.com/services/business-assessment" />
        <meta name="author" content="LariatCo" />

        <meta property="og:site_name" content="LariatCo" />
        <meta property="og:title" content="Business Assessments | Identify, Optimize, and Grow with LariatCo" />
        <meta property="og:description" content="Uncover vulnerabilities and streamline operations with LariatCo’s comprehensive business assessments. Build a strategy for efficiency, compliance, and modernization." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.lariatco.com/services/business-assessment" />
        <meta property="og:image" content="https://www.lariatco.com/twogcard.png" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Business Assessments | Identify, Optimize, and Grow with LariatCo" />
        <meta name="twitter:description" content="LariatCo’s business assessments help you identify inefficiencies, ensure compliance, and build a roadmap for scalable, long-term growth." />
        <meta name="twitter:image" content="https://www.lariatco.com/twogcard.png" /> */}
      </Helmet>
        <Header />
        <Container>
        <SubHeader />
          <TwoColumn>
            <MainFeature1
              subheading={<Subheading>Business Assessment</Subheading>}
              heading={
                <>
                  <HighlightedText><span style={{ color: "#0000D3"}}>Clarity. Strategy. Growth.</span></HighlightedText>
                </>
              }
              description="Harness the full potential of your business with a tailored assessment designed to drive modernization and sustainable growth. Our comprehensive approach evaluates your data systems, compliance practices, and legacy technology, delivering actionable insights and clear strategies for improvement."
              icons={page1Icons}
              bottomDescription={bottomDescripton}
              />
            <SimpleContactUs />
          </TwoColumn>
        </Container>
        <Footer />
      </AnimationRevealPage>
    );
  };
